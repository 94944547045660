<template>
  <div class="animated fadeIn">
    <rotate-square2 id="loading-gif" v-show="loading"></rotate-square2>
    <b-card v-show="!loading">
      <h4><i class="fas fa-file-invoice"></i> Vendor Invoices</h4><h5><span class="text-muted"> Generate Invoices On Behalf Of A Vendor </span></h5> <hr>
          <h5>Select Vendor</h5>
          <multiselect @input="onVendorSelection()" v-model="vendorSelection" placeholder="Search or add vendor" tag-placeholder="Search or add vendor" track-by="vid" :options="listOfVendors" :multiple="false" :close-on-select="true" :clear-on-select="false" :preserve-search="true" :taggable="true" label="company_name" :preselect-first="true">
          </multiselect>
    </b-card>

    <b-card v-if="displayInvoice">
      <VendorCreateInvoice v-bind:fake_vendor="vendorSelection" :key="vendorSelection"></VendorCreateInvoice>
    </b-card>
  </div>
</template>

<script>
import Vue from 'vue'
import Multiselect from 'vue-multiselect'
import {RotateSquare2} from 'vue-loading-spinner'
import VendorCreateInvoice from './../vendor/VendorCreateInvoice';

export default {
  name: 'AccountantOtherInvoices',
  components : {
    Multiselect,
    RotateSquare2,
    VendorCreateInvoice
  },
  data : function () {
    return {
      listOfVendors : [],
      vendorSelection : [],
      loading : true,
      displayInvoice : false
    }
  },
  methods : {

    getListOfVendors(){
      this.$axios.get('/wts/get/projects')
      .then(response => {
        if (response.status === 200) {
          var projects = response.data.result;
          for (var i = 0; i < projects.length; i++) {
            this.$axios.get('/wts/get/vas/' + projects[i].projectid)
            .then(response => {
              this.listOfVendors = this.lodash.uniqBy(this.listOfVendors.concat(response.data.result), 'vid');
            })
          }
          this.loading = false;
        }
      })
      .catch(error => {
      })
    },

    onVendorSelection(){
      this.displayInvoice = true;
    }

  },
  mounted() {
    this.getListOfVendors();
  },
}

</script>
