var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      _c("rotate-square2", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading"
          }
        ],
        attrs: { id: "loading-gif" }
      }),
      _c(
        "b-card",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading,
              expression: "!loading"
            }
          ]
        },
        [
          _c("h4", [
            _c("i", { staticClass: "fas fa-file-invoice" }),
            _vm._v(" Vendor Invoices")
          ]),
          _c("h5", [
            _c("span", { staticClass: "text-muted" }, [
              _vm._v(" Generate Invoices On Behalf Of A Vendor ")
            ])
          ]),
          _c("hr"),
          _c("h5", [_vm._v("Select Vendor")]),
          _c("multiselect", {
            attrs: {
              placeholder: "Search or add vendor",
              "tag-placeholder": "Search or add vendor",
              "track-by": "vid",
              options: _vm.listOfVendors,
              multiple: false,
              "close-on-select": true,
              "clear-on-select": false,
              "preserve-search": true,
              taggable: true,
              label: "company_name",
              "preselect-first": true
            },
            on: {
              input: function($event) {
                return _vm.onVendorSelection()
              }
            },
            model: {
              value: _vm.vendorSelection,
              callback: function($$v) {
                _vm.vendorSelection = $$v
              },
              expression: "vendorSelection"
            }
          })
        ],
        1
      ),
      _vm.displayInvoice
        ? _c(
            "b-card",
            [
              _c("VendorCreateInvoice", {
                key: _vm.vendorSelection,
                attrs: { fake_vendor: _vm.vendorSelection }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }